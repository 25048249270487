import React from 'react'
import Wrapper from '../components/wrapper'

const FixedEmbed = ({ location }) => {
  return (
    <Wrapper location={location} title="Fixed Embed | MedReps.com">
      <div>
        <iframe
          src="https://e.infogram.com/4bbee25f-036b-4785-90b0-a18f9eacc1bb?src=embed"
          title="Report Classic"
          width="595"
          height="4292"
          scrolling="no"
          frameborder="0"
          style={{
            border: 'none',
          }}
          allowfullscreen="allowfullscreen"
        ></iframe>
        <div
          style={{
            padding: '8px 0',
            fontFamily: 'Arial !important',
            fontSize: '13px !important',
            lineHeight: '15px !important',
            textAlign: 'center',
            borderTop: '1px solid #dadada',
            margin: '0 30px',
            width: '535px',
          }}
        >
          <a
            href="https://infogram.com/4bbee25f-036b-4785-90b0-a18f9eacc1bb"
            style={{
              color: '#989898 !important',
              textDecoration: 'none !important',
            }}
            target="_blank"
          >
            Report Classic
          </a>
          <br />
          <a
            href="https://infogram.com"
            style={{
              color: '#989898 !important',
              textDecoration: 'none !important',
            }}
            target="_blank"
            rel="nofollow"
          >
            Infogram
          </a>
        </div>
      </div>
    </Wrapper>
  )
}
export default FixedEmbed
